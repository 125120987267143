<template>
  <section id="dashboard">
    <b-row>
      <b-col>
        <h2>Patients</h2>
      </b-col>
    </b-row>

    <b-row class="content-header">
      <b-col
        v-if="viewMode"
        md="12"
        class="mb-2 text-right"
      >
        <button
          v-if="viewMode"
          class="btn btn-primary"
          type="button"
          @click="addPatient"
        >
          Add Patient
        </button>
      </b-col>
      <b-col
        v-else
        md="12"
        class="mb-2 text-right"
      >
        <button
          class="btn btn-secondary mr-3"
          type="button"
          @click="cancelPatient"
        >
          Cancel
        </button>
        <button
          class="btn btn-success"
          type="button"
          @click="savePatient"
        >
          Save
        </button>
      </b-col>
    </b-row>

    <div
      v-if="viewMode"
      class="container mt-3 bg-white rounded p-5"
    >
      <h3 class="mb-3">
        All Patients
      </h3>
      <b-row class="match-height">
        <b-col>
          <div
            v-if="patients.length < 1"
            class="alert alert-info p-3"
          >
            No patients found.
          </div>
          <div v-else>
            <div class="table-responsive">
              <table class="table table-striped table-hover">
                <thead>
                  <tr>
                    <th>ID</th>
                    <th>First Name</th>
                    <th>Last Name</th>
                    <th>Reports Count</th>
                    <th>Clinician</th>
                    <th>Actions</th>
                  </tr>
                </thead>
                <tbody>
                  <tr
                    v-for="item in patients"
                    :key="item.id"
                  >
                    <td @click="viewProfile(item)">
                      {{ item.uuid }}
                    </td>
                    <td @click="viewProfile(item)">
                      {{ item.first_name }}
                    </td>
                    <td @click="viewProfile(item)">
                      {{ item.last_name }}
                    </td>
                    <td>{{ item.reports_count }}</td>
                    <td>{{ item.clinicians.length > 0 ? item.clinicians[0].name : "Not Assigned" }}</td>
                    <td>
                      <button
                        class="btn btn-danger btn-sm"
                        type="button"
                        @click="deletePatient(item)"
                      >
                        Delete
                      </button>
                    </td>
                  </tr>
                </tbody>
              </table>
              <b-pagination
                v-model="args.page"
                :total-rows="args.total"
                :per-page="args.size"
                align="center"
                class="my-1"
                @change="getPatients"
              />
            </div>
          </div>
        </b-col>
      </b-row>
    </div>
    <div
      v-else
      class="container mt-3 bg-white rounded p-5"
    >
      <h3 class="mb-3">
        Add Patient
      </h3>
      <b-row class="match-height">
        <b-col>
          <div class="form-group mb-3">
            <label for="patientFName">Patient First Name</label>
            <input
              id="patientFName"
              v-model="patient.first_name"
              type="text"
              class="form-control"
              placeholder="Enter patient first name"
            >
          </div>
          <div class="form-group mb-3">
            <label for="patientLName">Patient Last Name</label>
            <input
              id="patientLName"
              v-model="patient.last_name"
              type="text"
              class="form-control"
              placeholder="Enter patient last name"
            >
          </div>
        </b-col>
      </b-row>
    </div>

  </section>
</template>

<script>
import { BRow, BCol, BPagination } from 'bootstrap-vue'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'
import useJwt from '@/auth/jwt/useJwt'

export default {
  components: {
    BRow,
    BCol,
    BPagination,
  },
  data() {
    return {
      patients: [],
      viewMode: true,
      patient: {},
      sort: 'asc',
      filter: '',
      args: {
        page: 1,
        size: 20,
        total: 100,
      },
    }
  },
  mounted() {
    this.getPatients()
  },
  methods: {
    getPatients() {
      useJwt.getPatients(this.args).then(response => {
        this.patients = response.data.data
        this.args.total = response.data.links.last_page * this.args.size
      })
    },
    viewProfile(patient) {
      localStorage.setItem('patient', JSON.stringify(patient))
      // eslint-disable-next-line prefer-destructuring
      const uuid = patient.uuid
      this.$router.push({ name: 'view-patient', params: { uuid } })
    },
    addPatient() {
      this.viewMode = false
      this.patient = {}
    },
    cancelPatient() {
      this.viewMode = true
      this.patient = {}
    },
    savePatient() {
      useJwt.addPatient(this.patient).then(() => {
        this.viewMode = true
        this.patient = {}
        this.getPatients()
        this.$toast({
          component: ToastificationContent,
          position: 'top-right',
          props: {
            title: 'Patients',
            icon: 'CheckIcon',
            variant: 'success',
            text: 'Patient added successfully',
          },
        })
      },
      error => {
        let message
        if (error.response.status === 400) {
          const keys = Object.keys(error.response.data.error)
          if (keys.length > 0) {
            // eslint-disable-next-line prefer-destructuring
            message = error.response.data.error[keys[0]][0]
          } else {
            message = error.response.data.details
          }
        } else {
          message = 'Something went wrong. Please try later.'
        }
        this.$toast({
          component: ToastificationContent,
          position: 'top-right',
          props: {
            title: 'Patients',
            icon: 'AlertTriangleIcon',
            variant: 'danger',
            text: message,
          },
        })
      })
    },
    deletePatient(patient) {
      this.$swal({
        title: 'Are you sure?',
        text: "You won't be able to revert this!",
        icon: 'warning',
        showCancelButton: true,
        confirmButtonText: 'Delete',
        customClass: {
          confirmButton: 'btn btn-primary',
          cancelButton: 'btn btn-outline-danger ml-1',
        },
        buttonsStyling: false,
      }).then(result => {
        if (result.value) {
          useJwt.deletePatient(patient.uuid).then(() => {
            this.getPatients()
            this.$toast({
              component: ToastificationContent,
              position: 'top-right',
              props: {
                title: 'Patients',
                icon: 'CheckIcon',
                variant: 'success',
                text: 'Patient deleted successfully',
              },
            })
          },
          error => {
            let message
            if (error.response.status === 400) {
              const keys = Object.keys(error.response.data.error)
              if (keys.length > 0) {
                // eslint-disable-next-line prefer-destructuring
                message = error.response.data.error[keys[0]][0]
              } else {
                message = error.response.data.details
              }
            } else {
              message = 'Something went wrong. Please try later.'
            }
            this.$toast({
              component: ToastificationContent,
              position: 'top-right',
              props: {
                title: 'Patients',
                icon: 'AlertTriangleIcon',
                variant: 'danger',
                text: message,
              },
            })
          })
        }
      })
    },
  },
}
</script>

<style lang="scss">
// @import '@core/scss/vue/pages/dashboard-ecommerce.scss';
// @import '@core/scss/vue/libs/chart-apex.scss';
</style>
